<template>
  <v-container>
    <Owner_types_list/>
  </v-container>
</template>

<script>
import Owner_types_list from "@/components/cib_components/owner_types_sub_component/owner_types_list";

export default {
  name: "LoanTypes",
  components: {Owner_types_list}
}
</script>

<style scoped>

</style>